import React from 'react';

export default function IndexSectionPricingWhitePattern27() {
    return (
        <React.Fragment>
            <>
                <section className="py-20 xl:pt-24 xl:pb-28 bg-white " style={{backgroundImage: 'url("flex-ui-assets/elements/pattern-white.svg")', backgroundPosition: 'center'}}><div className="container px-4 mx-auto text-center">
    <span className="inline-block py-px px-2 mb-6 text-xs leading-5 font-medium uppercase rounded-9xl text-amber-600 bg-amber-100">Pricing</span><h3 className="mb-4 text-3xl md:text-5xl text-coolGray-900 font-bold tracking-tighter text-center">Flexible Plans for Every Trader</h3>
    <p className="mb-12 text-lg md:text-xl text-coolGray-500 font-medium text-center">Choose a plan that fits your trading style and volume.</p>
    <div className="flex flex-wrap justify-center -mx-4">
      <div className="w-full md:w-1/2 lg:w-1/3 p-4">
        <div className="flex flex-col items-center pt-10 px-8 pb-8 bg-coolGray-50 rounded-md shadow-md transition text-center">
          <span className="inline-block py-px px-2 mb-6 text-xs leading-5 font-medium uppercase rounded-9xl text-amber-600 bg-amber-100">Pricing</span>
          <h3 className="mb-4 text-lg md:text-xl font-medium text-amber-600">Basic Plan</h3>
          <div className="mb-4">
            <span className="relative -top-6 right-1 text-2xl text-coolGray-900 font-medium">$</span>
            <span className="text-5xl text-coolGray-900 font-medium">10</span>
            <span className="text-3xl text-coolGray-900 font-medium">/mo</span>
          </div>
          <p className="mb-7 text-lg md:text-xl text-coolGray-400 font-medium">Billed annually</p>
          <ul className="self-start mb-8">
            <li className="flex items-center mb-3 text-coolGray-500 font-medium">
              <img className="mr-2 h-6" src={require('../../assets/images/check.png')} /><span>Billed annually</span>
            </li>
            <li className="flex items-center mb-3 text-coolGray-500 font-medium">
              <img className="mr-3 h-6" src={require('../../assets/images/check.png')} /><span>Basic market analysis</span>
            </li>
            <li className="flex items-center mb-3 text-coolGray-500 font-medium">
              <img className="mr-2 h-6" src={require('../../assets/images/check.png')} /><span>150 watchlist items</span>
            </li>
            <li className="flex items-center text-coolGray-500 font-medium">
              <img className="mr-2 h-6" src={require('../../assets/images/check.png')} /><span>Email support</span>
            </li>
          </ul>
        </div>
      </div>
      <div className="w-full md:w-1/2 lg:w-1/3 p-4">
        <div className="flex flex-col items-center pt-10 px-8 pb-8 bg-coolGray-50 rounded-md shadow-md hover:scale-105 transition duration-500">
          <span className="inline-block py-px px-2 mb-6 text-xs leading-5 font-medium uppercase rounded-9xl text-amber-600 bg-amber-100">Pricing</span>
          <h3 className="mb-4 text-lg md:text-xl font-medium text-amber-600">Freelancer Plan</h3>
          <div className="mb-4">
            <span className="relative -top-6 right-1 text-2xl text-coolGray-900 font-medium">$</span>
            <span className="text-5xl text-coolGray-900 font-medium">99</span>
            <span className="text-3xl text-coolGray-900 font-medium">/mo</span>
          </div>
          <p className="mb-7 text-lg md:text-xl text-coolGray-400 font-medium">Billed annually</p>
          <ul className="self-start mb-8">
            <li className="flex items-center mb-3 text-coolGray-500 font-medium">
              <img className="mr-2 h-6" src={require('../../assets/images/check.png')} /><span>Unlimited AI trading signals</span>
            </li>
            <li className="flex items-center mb-3 text-coolGray-500 font-medium">
              <img className="mr-3 h-6" src={require('../../assets/images/check.png')} /><span>Advanced technical analysis</span>
            </li>
            <li className="flex items-center mb-3 text-coolGray-500 font-medium">
              <img className="mr-2 h-6" src={require('../../assets/images/check.png')} /><span>Unlimited watchlist</span>
            </li>
            <li className="flex items-center text-coolGray-500 font-medium">
              <img className="mr-2 h-6" src={require('../../assets/images/check.png')} /><span>24/7 priority support</span>
            </li>
          </ul>
        </div>
      </div>
      <div className="w-full md:w-1/2 lg:w-1/3 p-4">
        <div className="flex flex-col items-center pt-10 px-8 pb-8 rounded-md shadow-md hover:scale-105 transition duration-500">
          <span className="inline-block py-px px-2 mb-6 text-xs leading-5 font-medium uppercase rounded-9xl text-amber-600 bg-amber-100">Pricing</span>
          <h3 className="mb-4 text-lg md:text-xl font-medium text-amber-600">Enterprise Plan</h3>
          <div className="mb-4">
            <span className="relative -top-6 right-1 text-2xl text-coolGray-900 font-medium">$</span>
            <span className="text-5xl text-coolGray-900 font-medium">799</span>
            <span className="text-3xl text-coolGray-900 font-medium">/year</span>
          </div>
          <p className="mb-7 text-lg md:text-xl text-coolGray-400 font-medium">Billed annually</p>
          <ul className="self-start mb-8">
            <li className="flex items-center mb-3 text-coolGray-500 font-medium">
              <img className="mr-2 h-6" src={require('../../assets/images/check.png')} /><span>VIP AI trading signals</span>
            </li>
            <li className="flex items-center mb-3 text-coolGray-500 font-medium">
              <img className="mr-3 h-6" src={require('../../assets/images/check.png')} /><span className>Personalized predictions</span>
            </li>
            <li className="flex items-center mb-3 text-coolGray-500 font-medium">
              <img className="mr-2 h-6" src={require('../../assets/images/check.png')} /><span>Dedicated account manager</span>
            </li>
            <li className="flex items-center text-coolGray-500 font-medium">
              <img className="mr-2 h-6" src={require('../../assets/images/check.png')} /><span>on-one coaching sessions</span>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</section>


            </>
        </React.Fragment>
    );
}

