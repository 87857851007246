import React from 'react';

export default function AboutSection__structures7() {
    return (
        <React.Fragment>
            <>
                <div>
  <div>
    <div>
      <div className="relative w-full h-96 overflow-hidden  shadow-lg">
        <img className="w-full h-full object-cover" src={require('../../assets/images/1-3.png')} alt="Image" />
        <div className="absolute inset-0 flex flex-col items-center justify-center p-6 text-center">
          <h2 className="text-white text-3xl font-bold mb-2">About us</h2>
          <p className="mb-4 text-amber-100">A short description of the About us.</p>
          <a href="index.html" className="inline-block text-black px-3 py-1.5 rounded-full transition-colors bg-amber-600 hover:bg-amber-600">
            <span className="text-sm font-semibold">Home</span>
            <svg className="inline-block w-4 h-4 ml-2" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6" />
            </svg>
          </a>
        </div>
      </div>
    </div>
  </div>
</div>


            </>
        </React.Fragment>
    );
}

