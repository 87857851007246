import React from 'react';
import {
  Routes,
  Route
} from 'react-router-dom';

import IndexPage from './pages/Index.js';
import AboutPage from './pages/About.js';
import WorksPage from './pages/Works.js';
import ContactPage from './pages/Contact.js';
import BlogPage from './pages/Blog.js';

function App() {
  return (
    <Routes>
      <Route path="/" element={<IndexPage />} />
         
      <Route path="/about" element={<AboutPage />} />
         
      <Route path="/works" element={<WorksPage />} />
         
      <Route path="/contact" element={<ContactPage />} />
         
      <Route path="/blog" element={<BlogPage />} />
    </Routes>
  );
}

export default App;
