import React, { useState } from 'react';

const FAQItem = ({ question, answer }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  return (
    <div className="mb-10">
      <button 
        className="flex flex-wrap w-full text-left focus:outline-none" 
        onClick={() => setIsExpanded(!isExpanded)}
        style={{ color: 'rgb(52, 211, 153)' }} // Set button text color to the specified green
      >
        <div className="w-full md:w-auto md:pr-10 mb-2 md:mb-0">
          <h3 className="text-xl text-coolGray-900 font-bold hover:text-amber-600">
            {question}
          </h3>
        </div>
        <div className="ml-auto text-amber-600">
          <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path 
              d={isExpanded ? "M5 15l7-7 7 7" : "M19 9l-7 7-7-7"}
              stroke="currentColor" 
              strokeWidth="2" 
              strokeLinecap="round" 
              strokeLinejoin="round"
            />
          </svg>
        </div>
      </button>
      {isExpanded && (
        <p className="mt-4 text-coolGray-500 font-medium">
          {answer}
        </p>
      )}
    </div>
  );
};

const FAQSection = () => {
  const faqs = [
    {
      question: "How can I customize my trading alerts?",
      answer: "You can customize your trading alerts in the settings section of your account. Options include frequency, asset types, and risk levels."
    },
    {
      question: "Can View Coinx integrate with my existing trading account?",
      answer: "Yes, View Coinx can integrate with most major trading platforms. Check our integration page for a list of supported brokers and exchanges."
    },
    {
      question: "How do I make changes to an existing order?",
      answer: "To modify an existing order, go to the 'Open Orders' section in your account, select the order you wish to change, and click on 'Modify Order'."
    },
    {
      question: "When will my order arrive?",
      answer: "Order execution times vary depending on market conditions and order types. Market orders are typically filled immediately, while limit orders are filled when the specified price is reached."
    }
  ];

  return (
    <div>
      <section className="py-24 bg-white" style={{backgroundImage: 'url("flex-ui-assets/elements/pattern-white.svg")', backgroundPosition: 'center'}}>
        <div className="container px-4 mx-auto">
          <div className="flex flex-wrap -mx-4">
            <div className="w-full md:w-1/2 px-4 mb-20 md:mb-0 text-center">
              <div className="max-w-md">
                <span className="inline-block py-px px-2 mb-4 text-xs leading-5 font-medium rounded-full shadow-sm text-amber-600 bg-amber-100">FAQ</span>
                <img className="rounded-7xl" src={require('../../assets/images/1-dark.png')} alt="" />
                <h2 className="mb-4 text-4xl md:text-5xl leading-tight text-coolGray-900 font-bold tracking-tighter">Frequently Asked Questions</h2>
                <p className="text-lg md:text-xl text-coolGray-500 font-medium">How reliable are your AI trading signals? Our signals are generated using cutting-edge AI technology and real-time data, ensuring high accuracy. However, all trading involves risk.</p>
              </div>
            </div>
            <div className="w-full md:w-1/2 px-4">
              {faqs.map((faq, index) => (
                <FAQItem key={index} question={faq.question} answer={faq.answer} />
              ))}
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default FAQSection;