import React, { useState, useEffect } from 'react';
import axios from 'axios';
import styled from 'styled-components';
import { useSpring, animated } from '@react-spring/web';

const PriceBoxWrapper = styled(animated.div)`
  display: flex;
  align-items: center;
  padding: 16px;
  background-color: #1a202c;
  border-radius: 16px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.5s, box-shadow 0.5s;
  width: 100%;

  &:hover {
    transform: scale(1.05);
    box-shadow: 0 8px 12px rgba(0, 0, 0, 0.2);
  }
`;

const CoinImage = styled.img`
  width: 48px;
  height: 48px;
  border-radius: 50%;
  border: 2px solid #3b82f6;
  margin-right: 16px;
`;

const CoinInfo = styled.div`
  flex: 1;
`;

const CoinName = styled.h3`
  color: #e2e8f0;
  font-size: 1.125rem;
  font-weight: 700;
  margin-bottom: 4px;
`;

const CoinSymbol = styled.p`
  color: #a0aec0;
  font-size: 0.75rem;
`;

const PriceInfo = styled.div`
  text-align: right;
`;

const CoinPrice = styled.p`
  font-size: 1.25rem;
  font-weight: 600;
  margin-bottom: 4px;
  color: ${({ priceChange }) => (priceChange >= 0 ? '#4ade80' : '#ef4444')};
`;

const PriceChange = styled.p`
  font-size: 0.75rem;
  color: ${({ priceChange }) => (priceChange >= 0 ? '#4ade80' : '#ef4444')};
`;

const PriceBox = ({ symbol, name, imageUrl }) => {
  const [price, setPrice] = useState('Loading...');
  const [priceChange, setPriceChange] = useState(0);

  const springProps = useSpring({
    from: { opacity: 0, transform: 'scale(0.9)' },
    to: { opacity: 1, transform: 'scale(1)' },
    config: { duration: 500 },
  });

  useEffect(() => {
    const fetchPrice = async () => {
      try {
        const response = await axios.get(`https://api.binance.com/api/v3/ticker/24hr?symbol=${symbol}USDT`);
        const currentPrice = parseFloat(response.data.lastPrice);
        const change = parseFloat(response.data.priceChangePercent);

        const formattedPrice = symbol === 'SHIB' 
          ? `$${currentPrice.toFixed(8)}`
          : `$${currentPrice.toFixed(2)}`;

        setPrice(formattedPrice);
        setPriceChange(change);
      } catch (error) {
        console.error(`Error fetching price for ${symbol}:`, error);
      }
    };

    fetchPrice();
    const interval = setInterval(fetchPrice, 10000);

    return () => clearInterval(interval);
  }, [symbol]);

  return (
    <PriceBoxWrapper style={springProps}>
      <CoinImage src={imageUrl} alt={symbol} />
      <CoinInfo>
        <CoinName>{name}</CoinName>
        <CoinSymbol>{symbol}</CoinSymbol>
      </CoinInfo>
      <PriceInfo>
        <CoinPrice priceChange={priceChange}>{price}</CoinPrice>
        <PriceChange priceChange={priceChange}>
          {priceChange >= 0 ? '▲' : '▼'} {Math.abs(priceChange).toFixed(2)}%
        </PriceChange>
      </PriceInfo>
    </PriceBoxWrapper>
  );
};





const PriceContainerWrapper = styled.div`
  background-image: url(${require('../../assets/images/6614b54c1fb041712633164.jpg')});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  position: relative;
  min-height: 100vh; // تغيير من 90vh إلى 100vh لتغطية الشاشة بالكامل
  padding: 16px;
  display: flex;
  align-items: center;
  justify-content: center;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.7);
    z-index: 1;
  }

  & > * {
    position: relative;
    z-index: 2;
  }
`;


const Container = styled.div`
  width: 100%;
  max-width: 1120px;
  z-index: 1;
`;
const Title = styled.h1`
  font-size: 2.25rem;
  font-weight: 700;
  color: #fff;
  margin-bottom: 24px;
  text-align: center;
`;

const PriceGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(240px, 1fr));
  gap: 16px;
  justify-content: center;
`;
const PriceContainer = () => {
  const coins = [
    { symbol: 'BTC', name: 'Bitcoin', imageUrl: 'https://assets.coincap.io/assets/icons/btc@2x.png' },
    { symbol: 'ETH', name: 'Ethereum', imageUrl: 'https://assets.coincap.io/assets/icons/eth@2x.png' },
    { symbol: 'API3', name: 'API3', imageUrl: 'https://assets.coincap.io/assets/icons/api3@2x.png' },
    { symbol: 'BNB', name: 'Binance Coin', imageUrl: 'https://assets.coincap.io/assets/icons/bnb@2x.png' },
    { symbol: 'XRP', name: 'Ripple', imageUrl: 'https://assets.coincap.io/assets/icons/xrp@2x.png' },
    { symbol: 'USDC', name: 'USD Coin', imageUrl: 'https://assets.coincap.io/assets/icons/usdc@2x.png' },
    { symbol: 'SOL', name: 'Solana', imageUrl: 'https://assets.coincap.io/assets/icons/sol@2x.png' },
    { symbol: 'ADA', name: 'Cardano', imageUrl: 'https://assets.coincap.io/assets/icons/ada@2x.png' },
    { symbol: 'DOGE', name: 'Dogecoin', imageUrl: 'https://assets.coincap.io/assets/icons/doge@2x.png' },
    { symbol: 'TRX', name: 'TRON', imageUrl: 'https://assets.coincap.io/assets/icons/trx@2x.png' },
    { symbol: 'DOT', name: 'Polkadot', imageUrl: 'https://assets.coincap.io/assets/icons/dot@2x.png' },
    { symbol: 'MATIC', name: 'Polygon', imageUrl: 'https://assets.coincap.io/assets/icons/matic@2x.png' },
    { symbol: 'LTC', name: 'Litecoin', imageUrl: 'https://assets.coincap.io/assets/icons/ltc@2x.png' },
    { symbol: 'AVAX', name: 'Avalanche', imageUrl: 'https://assets.coincap.io/assets/icons/avax@2x.png' },
    { symbol: 'LINK', name: 'Chainlink', imageUrl: 'https://assets.coincap.io/assets/icons/link@2x.png' },
    { symbol: 'SHIB', name: 'Shiba Inu', imageUrl: 'https://assets.coincap.io/assets/icons/shib@2x.png' }
];

  return (
    <PriceContainerWrapper>
      <Container>
        <Title>Crypto Price Tracker</Title>
        <PriceGrid>
          {coins.map((coin) => (
            <PriceBox key={coin.symbol} {...coin} />
          ))}
        </PriceGrid>
      </Container>
    </PriceContainerWrapper>
  );
};

export default PriceContainer;
