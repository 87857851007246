import React, { useState } from 'react';
import { useSpring, animated, config } from 'react-spring';

export default function IndexSectionHeadersDarkMix36() {
  const fadeIn = useSpring({
    from: { opacity: 0 },
    to: { opacity: 1 },
    config: config.molasses,
  });

  return (
    <animated.section style={fadeIn} className="relative bg-black overflow-hidden">
      <Navigation />
      <MainContent />
      <BottomWave />
    </animated.section>
  );
}

function Navigation() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  return (
    <nav className="flex justify-between p-6 px-4">
      <div className="flex justify-between items-center w-full">
        <Logo />
        <DesktopMenu />
        <ContactButton />
      </div>
      <MobileMenuButton isMenuOpen={isMenuOpen} setIsMenuOpen={setIsMenuOpen} />
      {isMenuOpen && <MobileMenu setIsMenuOpen={setIsMenuOpen} />}
    </nav>
  );
}

function Logo() {
  return (
    <div className="w-1/2 xl:w-1/3">
      <a className="block max-w-max" href="/">
        <img className="ll" src={require('../../assets/images/222Asset-1-at-2x.png')} alt="Logo" />
      </a>
    </div>
  );
}

function DesktopMenu() {
  return (
    <div className="w-1/2 xl:w-1/3">
      <ul className="hidden xl:flex xl:justify-center">
        <MenuItem href="/" text="Home" />
        <MenuItem href="about" text="About us" />
        <MenuItem href="works" text="How to work" />
        <MenuItem href="blog" text="Blog" />
      </ul>
    </div>
  );
}

function MenuItem({ href, text }) {
  return (
    <li className="mr-12">
      <a className="text-coolGray-400 hover:text-coolGray-50 font-medium" href={href}>{text}</a>
    </li>
  );
}

function ContactButton() {
  return (
    <div className="w-1/2 xl:w-1/3">
      <div className="hidden xl:flex items-center justify-end">
        <a className="inline-block py-2 px-4 text-sm leading-5 text-violet-50 font-medium focus:ring-2 focus:ring-opacity-50 rounded-md bg-amber-600 hover:bg-amber-600 focus:bg-amber-900" href="contact">
          Contacts
        </a>
      </div>
    </div>
  );
}

function MobileMenuButton({ isMenuOpen, setIsMenuOpen }) {
  return (
    <button
      onClick={() => setIsMenuOpen(!isMenuOpen)}
      type="button"
      className="inline-flex items-center p-2 ml-1 text-sm text-white rounded-lg xl:hidden hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-gray-600 dark:hover:bg-gray-600 dark:focus:ring-gray-500"
      aria-controls="mobile-menu-2"
      aria-expanded={isMenuOpen}
    >
      <svg
        className={`w-6 h-6 ${isMenuOpen ? 'hidden' : 'block'}`}
        fill="currentColor"
        viewBox="0 0 20 20"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z"
          clipRule="evenodd"
        />
      </svg>
      <svg
        className={`w-6 h-6 ${isMenuOpen ? 'block' : 'hidden'}`}
        fill="currentColor"
        viewBox="0 0 20 20"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
          clipRule="evenodd"
        />
      </svg>
    </button>
  );
}
function MobileMenu({ setIsMenuOpen }) {
  const menuAnimation = useSpring({
    from: { opacity: 0, transform: 'translateX(-100%)' },
    to: { opacity: 1, transform: 'translateX(0)' },
    config: config.stiff,
  });

  return (
    <animated.div
      style={menuAnimation}
      className="fixed inset-0 z-50 bg-black bg-opacity-90 flex flex-col items-center justify-center p-4"
    >
      <button
        className="absolute top-4 right-4 text-white focus:outline-none"
        onClick={() => setIsMenuOpen(false)}
      >
        <svg
          className="h-6 w-6"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
          aria-hidden="true"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M6 18L18 6M6 6l12 12"
          />
        </svg>
      </button>
      <div className="flex flex-col items-center space-y-6">
        <a
          href="/"
          className="block px-4 py-2 text-2xl sm:text-3xl text-white hover:text-amber-600 transition-colors duration-300 w-full text-center"
        >
          Home
        </a>
        <a
          href="/about"
          className="block px-4 py-2 text-2xl sm:text-3xl text-white hover:text-amber-600 transition-colors duration-300 w-full text-center"
        >
          About
        </a>
        <a
          href="/services"
          className="block px-4 py-2 text-2xl sm:text-3xl text-white hover:text-amber-600 transition-colors duration-300 w-full text-center"
        >
          Services
        </a>
        <a
          href="/contact"
          className="block px-4 py-2 text-2xl sm:text-3xl text-white hover:text-amber-600 transition-colors duration-300 w-full text-center"
        >
          Contact
        </a>
      </div>
    </animated.div>
  );
}

function MainContent() {
  const slideUp = useSpring({
    from: { transform: 'translateY(50px)', opacity: 0 },
    to: { transform: 'translateY(0)', opacity: 1 },
    config: config.gentle,
    delay: 300,
  });

  const scaleIn = useSpring({
    from: { transform: 'scale(0.9)', opacity: 0 },
    to: { transform: 'scale(1)', opacity: 1 },
    config: config.gentle,
    delay: 600,
  });

  return (
    <div className="py-20 md:py-28 bg-inherit" style={{backgroundImage: `url(${require("../../assets/images/bg-dark.png")})`, backgroundPosition: 'center'}}>
      <div className="container px-4 mx-auto">
        <div className="flex flex-wrap xl:items-center -mx-4">
          <animated.div style={slideUp} className="w-full md:w-1/2 px-4 md:mb-0 text-center">
            <TextContent />
          </animated.div>
          <animated.div style={scaleIn} className="w-full md:w-1/2 px-4">
            <ImageContent />
          </animated.div>
        </div>
      </div>
    </div>
  );
}

function TextContent() {
  const textAnimation = useSpring({
    from: { opacity: 0, transform: 'translateY(20px)' },
    to: { opacity: 1, transform: 'translateY(0)' },
    config: config.gentle,
    delay: 900,
  });

  return (
    <animated.div style={textAnimation}>
      <span className="inline-block py-px px-2 mb-4 text-xs leading-5 text-white uppercase rounded-9xl bg-amber-600 justify-center">Header</span>
      <h1 className="mb-6 text-3xl md:text-5xl lg:text-6xl leading-tight text-white font-bold tracking-tight">
        Maximize Your Trading Potential with AI-Driven Signals
      </h1>
      <p className="mb-8 text-lg md:text-xl text-coolGray-400 font-medium">
        Welcome to View Coinx. Experience the future of trading with our advanced AI-powered signals, tailored to give you an edge in Crypto markets and all coins on Blockchain.
      </p>
      <div className="flex justify-center inline-flex">
        <img src="flex-ui-assets/elements/app-store-gray.svg" className="py-5 inline-flex px-2" alt="App Store" />
        <img src="flex-ui-assets/elements/google-play-gray.svg" className="py-5 inline-flex px-2" alt="Google Play" />
      </div>
    </animated.div>
  );
}

function ImageContent() {
  const float = useSpring({
    from: { transform: 'translateY(0px)' },
    to: async (next) => {
      while (1) {
        await next({ transform: 'translateY(10px)' });
        await next({ transform: 'translateY(-10px)' });
      }
    },
    config: { duration: 3000 },
  });

  return (
    <div className="relative mx-auto md:mr-0 max-w-max">
      <img className="absolute z-10 -left-8 -top-8 w-28 md:w-auto text-yellow-400" src="flex-ui-assets/elements/circle3-green.svg" alt="" />
      <img className="absolute z-10 -right-7 -bottom-8 w-28 md:w-auto text-blue-500" src="flex-ui-assets/elements/dots3-yellow.svg" alt="" />
      <animated.img 
        style={float}
        className="relative rounded-7xl" 
        src={require('../../assets/images/1.png')} 
        alt="Main" 
      />
    </div>
  );
}

function BottomWave() {
  const waveAnimation = useSpring({
    from: { transform: 'translateY(100%)', opacity: 0 },
    to: { transform: 'translateY(0)', opacity: 1 },
    config: config.gentle,
    delay: 1200,
  });

  return (
    <animated.div style={waveAnimation} className="wave-bottom w-full text-coolGray-900">
      <svg viewBox="0 0 1440 116" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M1440 51.4091H1090.08C833.336 51.4091 580.229 116 360 116C139.771 116 0 51.4091 0 51.4091V0H1440V51.4091Z" fill="fff" />
      </svg>
    </animated.div>
  );
}