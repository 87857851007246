import React from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import WorksSectionNavigationsWhite1 from '../components/navigations-white/WorksSectionNavigationsWhite1';
import WorksSection__structures5 from '../components/__structures/WorksSection__structures5';
import WorksSectionHowItWorksWhitePattern3 from '../components/how-it-works-white-pattern/WorksSectionHowItWorksWhitePattern3';
import WorksSectionFaqsGrayMix11 from '../components/faqs-gray-mix/WorksSectionFaqsGrayMix11';
import WorksSectionNumbersWhitePattern10 from '../components/numbers-white-pattern/WorksSectionNumbersWhitePattern10';
import WorksSectionNewsletterWhitePattern8 from '../components/newsletter-white-pattern/WorksSectionNewsletterWhitePattern8';
import WorksSectionFootersWhitePattern6 from '../components/footers-white-pattern/WorksSectionFootersWhitePattern6';

const meta = {
  title: 'Works',
  meta: [],
  link: [],
  style: [],
  script: [],
};

export default function Works() {
  return (
    <React.Fragment>
      <HelmetProvider>
        <Helmet {...meta}></Helmet>
      </HelmetProvider>
      <WorksSectionNavigationsWhite1 />
      <WorksSection__structures5 />
      <WorksSectionHowItWorksWhitePattern3 />
      <WorksSectionFaqsGrayMix11 />
      <WorksSectionNumbersWhitePattern10 />
      <WorksSectionNewsletterWhitePattern8 />
      <WorksSectionFootersWhitePattern6 />
    </React.Fragment>
  );
}

