import React from 'react';

export default function IndexSectionApplicationsDarkMix37() {
    return (
        <React.Fragment>
            <>
                <section className="wave-top bg-white "><div className="wave wave-top w-full text-coolGray-900" />
                <div 
  className="pt-24 bg-slate-500 bg-slate-600 bg-slate-700 bg-slate-900 bg-slate-950" 
  style={{backgroundImage: `url(${require("../../assets/images/bg-dark.png")})`, backgroundPosition: 'center'}}
>    <div className="container px-4 mx-auto">
      <div className="text-center">
        <span className="inline-block py-px px-2 mb-4 text-xs leading-5 font-medium rounded-full shadow-sm text-amber-600 bg-amber-100">MOBILE ACCESS</span>
        <h3 className="mb-4 text-4xl md:text-5xl leading-tight text-white font-bold tracking-tighter">Trade Anytime, Anywhere</h3>
        <p className="mb-6 mx-auto text-lg md:text-xl text-coolGray-400 font-medium max-w-2xl">Download our mobile app to receive AI-driven trading signals, monitor your portfolio, and execute trades on-the-go.</p>
        <div className="flex flex-wrap justify-center mb-16">
          <div className="w-full md:w-auto mb-3 md:mb-0 md:mr-4">
            <img className="mx-auto #" src="flex-ui-assets/elements/app-store-gray.svg" alt />
          </div>
          <div className="w-full md:w-auto">
            <img className="mx-auto"  src="flex-ui-assets/elements/google-play-gray.svg" alt />
          </div>
        </div>
        <div className="flex flex-row items-end mx-auto max-w-max">
          <div className="relative">
            <img className="mx-auto h-64 md:h-auto object-cover" src="flex-ui-assets/elements/applications/iphone-dark-half1.png" alt />
          </div>
          <div className="relative z-10 hidden md:block md:-ml-24">
            <img className="mx-auto" src="flex-ui-assets/elements/applications/iphone-dark-half2.png" alt />
          </div>
          <div className="relative hidden md:block md:-ml-24">
            <img className="mx-auto" src="flex-ui-assets/elements/applications/iphone-dark-half3.png" alt />
          </div>
        </div>
      </div>
    </div>
  </div>
</section>


            </>
        </React.Fragment>
    );
}

