import React from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import IndexSectionNavigationsWhite40 from '../components/navigations-white/IndexSectionNavigationsWhite40';
import IndexSectionHeadersDarkMix36 from '../components/headers-dark-mix/IndexSectionHeadersDarkMix36';
import IndexSectionLogoCloudsWhitePattern25 from '../components/logo-clouds-white-pattern/IndexSectionLogoCloudsWhitePattern25';
import IndexSectionFeaturesDarkPattern38 from '../components/features-dark-pattern/IndexSectionFeaturesDarkPattern38';
import IndexSectionFeaturesWhitePattern16 from '../components/features-white-pattern/IndexSectionFeaturesWhitePattern16';
import IndexSectionApplicationsDarkMix37 from '../components/applications-dark-mix/IndexSectionApplicationsDarkMix37';
import IndexSectionPricingWhitePattern27 from '../components/pricing-white-pattern/IndexSectionPricingWhitePattern27';
import IndexSectionNumbersDarkMix35 from '../components/numbers-dark-mix/IndexSectionNumbersDarkMix35';
import IndexSection__structures18 from '../components/__structures/IndexSection__structures18';
import IndexSectionFaqsWhitePattern12 from '../components/faqs-white-pattern/IndexSectionFaqsWhitePattern12';
import IndexSectionNewsletterWhitePattern20 from '../components/newsletter-white-pattern/IndexSectionNewsletterWhitePattern20';
import IndexSectionFootersDarkMix39 from '../components/footers-dark-mix/IndexSectionFootersDarkMix39';

const meta = {
  title: 'Home',
  meta: [],
  link: [],
  style: [],
  script: [],
};

export default function Index() {
  return (
    <React.Fragment>
      <HelmetProvider>
        <Helmet {...meta}></Helmet>
      </HelmetProvider>
      <IndexSectionNavigationsWhite40 />
      <IndexSectionHeadersDarkMix36 />

      {/* <IndexSectionLogoCloudsWhitePattern25 /> */}
      <IndexSectionFeaturesDarkPattern38 />
            <IndexSection__structures18 />

      <IndexSectionFeaturesWhitePattern16 />
      <IndexSectionApplicationsDarkMix37 />
      <IndexSectionPricingWhitePattern27 />
      <IndexSectionNumbersDarkMix35 />
      <IndexSectionFaqsWhitePattern12 />
      <IndexSectionNewsletterWhitePattern20 />
      <IndexSectionFootersDarkMix39 />
    </React.Fragment>
  );
}

