import React from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import AboutSectionNavigationsWhite18 from '../components/navigations-white/AboutSectionNavigationsWhite18';
import AboutSection__structures7 from '../components/__structures/AboutSection__structures7';
import AboutSectionTestimonialsWhitePattern12 from '../components/testimonials-white-pattern/AboutSectionTestimonialsWhitePattern12';
import AboutSectionLogoCloudsGrayPattern15 from '../components/logo-clouds-gray-pattern/AboutSectionLogoCloudsGrayPattern15';
import AboutSectionFeaturesGrayPattern14 from '../components/features-gray-pattern/AboutSectionFeaturesGrayPattern14';
import AboutSectionTeamGrayPattern16 from '../components/team-gray-pattern/AboutSectionTeamGrayPattern16';
import AboutSectionNewsletterWhitePattern4 from '../components/newsletter-white-pattern/AboutSectionNewsletterWhitePattern4';
import AboutSectionFootersWhitePattern3 from '../components/footers-white-pattern/AboutSectionFootersWhitePattern3';
import AboutSectionFootersWhitePattern11 from '../components/footers-white-pattern/AboutSectionFootersWhitePattern11';

const meta = {
  title: 'About',
  meta: [],
  link: [],
  style: [],
  script: [],
};

export default function About() {
  return (
    <React.Fragment>
      <HelmetProvider>
        <Helmet {...meta}></Helmet>
      </HelmetProvider>
      <AboutSectionNavigationsWhite18 />
      <AboutSection__structures7 />
      <AboutSectionTestimonialsWhitePattern12 />
      {/* <AboutSectionLogoCloudsGrayPattern15 /> */}
      <AboutSectionFeaturesGrayPattern14 />
      <AboutSectionTeamGrayPattern16 />
      <AboutSectionNewsletterWhitePattern4 />
      <AboutSectionFootersWhitePattern3 />
      <AboutSectionFootersWhitePattern11 />
    </React.Fragment>
  );
}

