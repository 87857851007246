import React, { useState } from 'react';

export default function AboutSectionNavigationsWhite18() {
    const [isMenuOpen, setIsMenuOpen] = useState(false);

    const toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen);
    };

    const closeMenu = () => {
        setIsMenuOpen(false);
    };

    return (
        <React.Fragment>
            <nav className="flex justify-between p-6 px-4 bg-black">
                <div className="flex justify-between items-center w-full">
                    <div className="w-1/2 xl:w-1/3">
                        <a className="block max-w-max" href="/">
                            <img className="ll" src={require('../../assets/images/222Asset-1-at-2x.png')} alt="Logo" />
                        </a>
                    </div>
                    <div className="w-1/2 xl:w-1/3">
                        <ul className="hidden xl:flex xl:justify-center">
                            <li className="mr-12"><a className="text-coolGray-400 hover:text-coolGray-50 font-medium" href="/">Home</a></li>
                            <li className="mr-12"><a className="text-coolGray-400 hover:text-coolGray-50 font-medium" href="/about">About us</a></li>
                            <li className="mr-12"><a className="text-coolGray-400 hover:text-coolGray-50 font-medium" href="/works">How to work</a></li>
                            <li><a className="text-coolGray-400 hover:text-coolGray-50 font-medium" href="/blog">Blog</a></li>
                        </ul>
                    </div>
                    <div className="w-1/2 xl:w-1/3">
                        <div className="hidden xl:flex items-center justify-end">
                            <a className="inline-block py-2 px-4 text-sm leading-5 text-violet-50 font-medium focus:ring-2 focus:ring-opacity-50 rounded-md bg-amber-600 hover:bg-amber-600 focus:bg-amber-900" href="/contact">Contacts</a>
                        </div>
                    </div>
                </div>
                <button className="navbar-burger self-center xl:hidden" onClick={toggleMenu}>
                    <svg width={35} height={35} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <rect className="text-coolGray-800" width={32} height={32} rx={6} fill="currentColor" />
                        <path className="text-coolGray-400" d="M7 12H25C25.2652 12 25.5196 11.8946 25.7071 11.7071C25.8946 11.5196 26 11.2652 26 11C26 10.7348 25.8946 10.4804 25.7071 10.2929C25.5196 10.1054 25.2652 10 25 10H7C6.73478 10 6.48043 10.1054 6.29289 10.2929C6.10536 10.4804 6 10.7348 6 11C6 11.2652 6.10536 11.5196 6.29289 11.7071C6.48043 11.8946 6.73478 12 7 12ZM25 15H7C6.73478 15 6.48043 15.1054 6.29289 15.2929C6.10536 15.4804 6 15.7348 6 16C6 16.2652 6.10536 16.5196 6.29289 16.7071C6.48043 16.8946 6.73478 17 7 17H25C25.2652 17 25.5196 16.8946 25.7071 16.7071C25.8946 16.5196 26 16.2652 26 16C26 15.7348 25.8946 15.4804 25.7071 15.2929C25.5196 15.1054 25.2652 15 25 15ZM25 20H7C6.73478 20 6.48043 20.1054 6.29289 20.2929C6.10536 20.4804 6 20.7348 6 21C6 21.2652 6.10536 21.5196 6.29289 21.7071C6.48043 21.8946 6.73478 22 7 22H25C25.2652 22 25.5196 21.8946 25.7071 21.7071C25.8946 21.5196 26 21.2652 26 21C26 20.7348 25.8946 20.4804 25.7071 20.2929C25.5196 20.1054 25.2652 20 25 20Z" fill="currentColor" />
                    </svg>
                </button>
                <div className={`navbar-menu fixed top-0 left-0 z-50 w-full h-full bg-coolGray-900 bg-opacity-50 ${isMenuOpen ? 'block' : 'hidden'}`}>
                    <div className="fixed top-0 left-0 bottom-0 w-4/6 max-w-xs bg-coolGray-900">
                        <nav className="relative p-6 h-full overflow-y-auto">
                            <div className="flex flex-col justify-between h-full">
                                <a className="inline-block" href="/">
                                    <img className="h-16" src={require('../../assets/images/222Asset-1-at-2x.png')} alt="Logo" />
                                </a>
                                <ul className="py-6 mb-52">
                                    <li><a className="block py-3 px-4 text-coolGray-400 hover:text-white font-medium hover:bg-coolGray-800 rounded-md" href="/">Home</a></li>
                                    <li><a className="block py-3 px-4 text-coolGray-400 hover:text-white font-medium hover:bg-coolGray-800 rounded-md" href="/about">About</a></li>
                                    <li><a className="block py-3 px-4 text-coolGray-400 hover:text-white font-medium hover:bg-coolGray-800 rounded-md" href="/works">How to work</a></li>
                                    <li><a className="block py-3 px-4 text-coolGray-400 hover:text-white font-medium hover:bg-coolGray-800 rounded-md" href="/blog">Blog</a></li>
                                </ul>
                                <div className="flex flex-wrap">
                                    <div className="w-full mb-2" />
                                    <div className="w-full">
                                        <a className="inline-block py-2 px-4 w-full text-sm leading-5 text-white font-medium text-center focus:ring-2 focus:ring-violet-500 focus:ring-opacity-50 rounded-md hover:bg-amber-600 bg-amber-600" href="/contact">Contact</a>
                                    </div>
                                </div>
                            </div>
                        </nav>
                        <button className="navbar-close absolute top-5 p-4 right-3" onClick={closeMenu}>
                            <svg width={12} height={12} viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M2 2 L10 10 M2 10 L10 2" stroke="currentColor" strokeWidth="2" strokeLinecap="round" />
                            </svg>
                        </button>
                    </div>
                </div>
            </nav>
        </React.Fragment>
    );
}
