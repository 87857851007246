import React from 'react';

export default function WorksSectionHowItWorksWhitePattern3() {
    return (
        <React.Fragment>
            <>
                <section className="py-24 bg-white overflow-hidden" style={{backgroundImage: 'url("flex-ui-assets/elements/pattern-white.svg")', backgroundPosition: 'center'}}>
  <div className="container px-4 mx-auto">
    <div className="flex flex-wrap -mx-4">
      <div className="w-full md:w-1/2 px-4 mb-16 md:mb-0">
        <div className="relative mx-auto md:ml-0 max-w-max">
          <img className="absolute z-10 -right-8 -top-8 w-28 md:w-auto" src="flex-ui-assets/elements/circle3-green.svg" alt />
          <img className="absolute z-10 -left-10 -bottom-8 w-28 md:w-auto" src="flex-ui-assets/elements/dots3-yellow.svg" alt />
          <img src={require('../../assets/images/1-2.png')} alt className="h-96" />
        </div>
      </div>
      <div className="w-full md:w-1/2 px-4 text-center">
        <span className="inline-block py-px px-2 mb-4 text-xs leading-5 font-medium uppercase rounded-full shadow-sm bg-amber-100 text-amber-600">How it works</span>
        <h2 className="mb-12 text-4xl md:text-5xl leading-tight font-bold tracking-tighter">Discover how View Coinx revolutionizes your trading experience:</h2>
        <div className="flex flex-wrap -mx-4 text-center md:text-left">
          <div className="w-full md:w-1/2 px-4 mb-8">
            <div className="inline-flex items-center justify-center mb-4 w-12 h-12 text-xl text-white font-semibold rounded-full bg-amber-600">1</div>
            <h3 className="mb-2 text-xl font-bold">Personalized Insights</h3>
            <p className="font-medium text-coolGray-500">Gain deep market insights with our advanced analytics dashboard. Monitor activity, revenue, and social metrics seamlessly in one cloud-based platform.</p>
          </div>
          <div className="w-full md:w-1/2 px-4 mb-8">
            <div className="inline-flex items-center justify-center mb-4 w-12 h-12 text-xl text-white font-semibold rounded-full bg-amber-600">2</div>
            <h3 className="mb-2 text-xl font-bold">Efficient Team Management</h3>
            <p className="font-medium text-coolGray-500">Stay organized with our integrated calendar, keeping track of customer interactions and project milestones to streamline your workflow.</p>
          </div>
          <div className="w-full md:w-1/2 px-4 mb-8">
            <div className="inline-flex items-center justify-center mb-4 w-12 h-12 text-xl text-white font-semibold rounded-full bg-amber-600">3</div>
            <h3 className="mb-2 text-xl font-bold">User-Friendly Setup</h3>
            <p className="font-medium text-coolGray-500">Experience our comprehensive End-to-End Business Platform, featuring sales management, marketing automation, help desk solutions, and more, designed for simplicity.</p>
          </div>
          <div className="w-full md:w-1/2 px-4">
            <div className="inline-flex items-center justify-center mb-4 w-12 h-12 text-xl text-white font-semibold rounded-full bg-amber-600">4</div>
            <h3 className="mb-2 text-xl font-bold">Website Builder</h3>
            <p className="font-medium text-coolGray-500">Create your ideal trading platform with our intuitive website building tool, perfect for users with no prior web design or programming knowledge.</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>


            </>
        </React.Fragment>
    );
}

