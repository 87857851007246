import React from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import BlogSectionNavigationsWhite5 from '../components/navigations-white/BlogSectionNavigationsWhite5';
import BlogSectionNavigationsWhite6 from '../components/navigations-white/BlogSectionNavigationsWhite6';
import BlogSection__structures7 from '../components/__structures/BlogSection__structures7';
import BlogSectionBlogWhitePattern9 from '../components/blog-white-pattern/BlogSectionBlogWhitePattern9';
import BlogSectionNewsletterWhitePattern4 from '../components/newsletter-white-pattern/BlogSectionNewsletterWhitePattern4';
import BlogSectionFootersWhitePattern3 from '../components/footers-white-pattern/BlogSectionFootersWhitePattern3';

const meta = {
  title: 'Blog',
  meta: [],
  link: [],
  style: [],
  script: [],
};

export default function Blog() {
  return (
    <React.Fragment>
      <HelmetProvider>
        <Helmet {...meta}></Helmet>
      </HelmetProvider>
      <BlogSectionNavigationsWhite5 />
      <BlogSectionNavigationsWhite6 />
      <BlogSection__structures7 />
      <BlogSectionBlogWhitePattern9 />
      <BlogSectionNewsletterWhitePattern4 />
      <BlogSectionFootersWhitePattern3 />
    </React.Fragment>
  );
}

