import React from 'react';

export default function IndexSectionNumbersDarkMix35() {
    return (
        <React.Fragment>
            <>
                <div className="bg-white">
  <div className="wave-top w-full text-coolGray-900">
    <svg viewBox="0 0 1440 116" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M1440 64.5909H1090.08C833.336 64.5909 580.229 -7.62939e-06 360 -7.62939e-06C139.771 -7.62939e-06 0 64.5909 0 64.5909V116H1440V64.5909Z" fill="#000" /></svg>
  </div>
  <section className="py-20 xl:pt-24 xl:pb-32 bg-black" style={{backgroundImage: `url(${require("../../assets/images/bg-dark.png")})`, backgroundPosition: 'center'}}><div className="container px-4 mx-auto">
      <div className="text-center">
        <span className="inline-block py-px px-2 mb-4 text-xs leading-5 font-medium uppercase rounded-9xl bg-amber-100 text-amber-600">OUR IMPACT</span>
        <h3 className="mb-4 text-4xl md:text-5xl text-white font-bold tracking-tighter">Driven by Data, Proven by Results</h3>
        <p className="mb-16 xl:mb-24 mx-auto text-lg md:text-xl text-coolGray-400 font-medium max-w-4xl">View Coinx is committed to delivering tailored AI insights and generating real results for traders worldwide.</p>
        <div className="flex flex-wrap justify-center -mx-4">
          <div className="w-full md:w-1/3 lg:w-1/4 px-4 mb-8 lg:mb-0">
            <h2 className="mb-2 text-4xl md:text-5xl text-white font-bold tracking-tighter">235.000</h2>
            <p className="text-lg md:text-xl text-coolGray-400 font-medium"> Successful trades executed</p>
          </div>
          <div className="w-full md:w-1/3 lg:w-1/4 px-4 mb-8 lg:mb-0">
            <h2 className="mb-2 text-4xl md:text-5xl text-white font-bold tracking-tighter">$10m</h2>
            <p className="text-lg md:text-xl text-coolGray-400 font-medium">User profits generated</p>
          </div>
          <div className="w-full md:w-1/3 lg:w-1/4 px-4 mb-8 lg:mb-0">
            <h2 className="mb-2 text-4xl md:text-5xl text-white font-bold tracking-tighter">+50.000</h2>
            <p className="text-lg md:text-xl text-coolGray-400 font-medium">Hours of market analysis conducted</p>
          </div>
          <div className="w-full md:w-1/3 lg:w-1/4 px-4">
            <h2 className="mb-2 text-4xl md:text-5xl text-white font-bold tracking-tighter">3.500</h2>
            <p className="text-lg md:text-xl text-coolGray-400 font-medium">Active traders globally</p>
          </div>
        </div>
      </div>
    </div>
  </section><div className="wave-bottom w-full text-coolGray-900">
    <svg viewBox="0 0 1440 116" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M0 51.4091H349.922C606.664 51.4091 859.771 116 1080 116C1300.23 116 1440 51.4091 1440 51.4091V0H0V51.4091Z" fill="#000" /></svg>
  </div>
</div>


            </>
        </React.Fragment>
    );
}

